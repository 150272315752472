import LogoImg from 'assets/images/main/careertest_logo.png';
import clsx from 'clsx';
import WithPadding from 'components/shared/WithPadding';
import { useUserContext } from 'contexts/AllContexts';
import { useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import HamburgerMenu from 'react-hamburger-menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import style from './Navbar.module.scss';

function isTokenPresent() {
  return 'accessToken' in localStorage || 'accessToken' in sessionStorage;
}

const _NavLinks = [
  {
    name: 'For Organization',
    link: '/for-organization',
  },
  {
    name: 'CareerPedia',
    link: '/careerpedia',
  },
  {
    name: 'About Us',
    link: '/about',
  },
  {
    name: 'Contact Us',
    link: '/contact-us',
  },
];

export default function Navbar({ background }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useUserContext();

  const [isMobile, setIsMobile] = useState(false);
  const [isHamOpen, setIsHamOpen] = useState(false);

  function isLoggedIn() {
    if (isTokenPresent()) return true;
    return false;
  }

  function handleClickButton(route) {
    handleHamClick();
    navigate(route);
  }

  const [navLinks, setNavLinks] = useState(
    _NavLinks.map((link) => ({
      ...link,
      isOpen: false,
    })),
  );

  function handleClickAway(e) {
    if (
      e.target.id === 'navLink' ||
      e.target.id === 'navLinkSvg' ||
      e.path === undefined
    )
      return;
    if (!e.path) return;

    const hamWrapper = e.path.find((f) => f.id === 'hamWrapper');
    if (hamWrapper) return;

    if (isHamOpen) setIsHamOpen(false);

    setNavLinks((prev) =>
      prev.map((item, ind) => {
        item.isOpen = false;
        return item;
      }),
    );
  }

  function openSublinks(index) {
    const toUpdate = [...navLinks].map((item, ind) => ({
      ...item,
      isOpen: false,
    }));
    toUpdate[index].isOpen = true;

    setNavLinks(toUpdate);
  }

  function closeSubLinks(index) {
    const toUpdate = [...navLinks].map((item, ind) => ({
      ...item,
      isOpen: false,
    }));

    setNavLinks(toUpdate);
  }

  function handleHamClick() {
    setIsHamOpen(!isHamOpen);
  }

  function resizeHandler() {
    if (window.innerWidth < 1400) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  function handleClickDashboard() {
    if (state.profile) {
      if (state.profile === 'VENDOR') navigate('/dashboard/vendor');
      else if (state.profile === 'CDE') navigate('/dashboard/counsellor');
      else navigate('/dashboard/candidate/assessment');
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, []);

  const validationRegex = /^\/auth\/[a-z0-9-]{3,30}\/login$/;

  if (
    validationRegex.test(location.pathname) ||
    location.pathname === '/auth/forgotpassword'
  ) {
    return null;
  }

  return (
    <WithPadding
      className={style.wrapper}
      style={{ background: background ? `${background}` : 'transparent' }}
    >
      {!isMobile && (
        <div className={style.desktopWrapper}>
          <div className={style.logo} onClick={() => navigate('/')}>
            <img src={LogoImg} alt="logo" />
          </div>
          <nav className={style.links}>
            {navLinks.map((link, index) => (
              <div key={link.name} className={style.link} to={link.link}>
                <div className={style.top}>
                  <Link
                    to={link.link}
                    id="navLink"
                    className={
                      location.pathname === link.link ? style.activeLink : ''
                    }
                  >
                    {link.name}
                  </Link>
                </div>
              </div>
            ))}
          </nav>
          {isLoggedIn() && state.profile ? (
            <div className={style.buttonDashboard}>
              <button
                onClick={handleClickDashboard}
                options={{ width: '100px' }}
              >
                Visit Dashboard
              </button>
            </div>
          ) : (
            <div className={style.buttons}>
              <button onClick={() => navigate('/auth/register')}>
                Start the Test
              </button>
              <button onClick={() => navigate('/auth/login')}>Login</button>
            </div>
          )}
        </div>
      )}

      {isMobile && (
        <div className={style.mobileWrapper}>
          <div className={style.logo} onClick={() => navigate('/')}>
            <img src={LogoImg} alt="logo" />
          </div>
          <div id="hamWrapper" className={clsx(style.hamWrapper)}>
            <HamburgerMenu
              id="hamWrapper"
              isOpen={isHamOpen}
              menuClicked={handleHamClick}
              width={25}
              height={15}
              color="#4B9BEE"
            />
          </div>
          <div className={clsx(style.links, isHamOpen && style.open)}>
            {navLinks.map((link, index) => (
              <div
                key={link.name}
                className={style.link}
                to={link.link}
                onClick={openSublinks.bind(this, index)}
              >
                <div className={style.top}>
                  {!link.sublinks ? (
                    <Link to={link.link} id="navLink">
                      {link.name}
                    </Link>
                  ) : (
                    <span id="navLink">{link.name}</span>
                  )}
                </div>
                {link.sublinks && (
                  <div
                    className={clsx(style.sublinks, link.isOpen && style.open)}
                  >
                    {link.sublinks.map((sublink) => (
                      <ClickAwayListener
                        key={sublink.name}
                        onClickAway={handleClickAway}
                      >
                        <Link className={style.sublink} to={sublink.link}>
                          {sublink.name}
                        </Link>
                      </ClickAwayListener>
                    ))}
                  </div>
                )}
              </div>
            ))}

            {isLoggedIn() ? (
              <div className={style.buttonDashboard}>
                <button
                  onClick={handleClickDashboard}
                  options={{ width: '100px' }}
                >
                  Visit Dashboard
                </button>
              </div>
            ) : (
              <div className={style.buttons}>
                <button
                  className={style.registerbtn}
                  onClick={() => handleClickButton('/auth/register')}
                >
                  Start the Test
                </button>
                <button
                  className={style.loginbtn}
                  onClick={() => handleClickButton('/auth/login')}
                >
                  Login
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </WithPadding>
  );
}
