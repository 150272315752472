import clsx from 'clsx';
import styles from './WithPadding.module.scss';

export default function WithPadding({ children, className, id, style }) {
  return (
    <div
      id={id}
      className={clsx(styles.wrapper, className)}
      style={style || {}}
    >
      {children}
    </div>
  );
}
