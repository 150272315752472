import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useDisableZohoChat = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const salesWidget = document.querySelector('[data-id="zsalesiq"]');

    if (!salesWidget) return;

    if (pathname === '/assessment/live') {
      salesWidget.style.display = 'none';
    } else {
      salesWidget.style.display = ''; // Reset to default display value
    }
  }, [pathname]);
};

export default useDisableZohoChat;
