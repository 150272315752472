import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postRequest } from 'utils/api';
import style from './GetStarted.module.scss';

export default function GetStarted() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ email: '' });

  function handleChangeForm(e) {
    const { value } = e.target;
    setFormData({ email: value });
  }

  async function handleGetStarted() {
    const { email } = formData;

    postRequest(
      '/web/subscriptions',
      {
        email,
      },
      { noAuth: true },
    )
      .then(() => {
        navigate(`/auth/register?email=${email}`);
      })
      .catch((e) => {
        console.error(e);
      });
  }
  return (
    <div className={style.getTouch}>
      <div className={style.blackBackground}>
        <Grid
          container
          spacing={3}
          className={style.container}
          style={{ width: '100%', margin: '0px' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={style.heading}>Empower Your Career Decisions</div>
            <Formik initialValues={formData} onSubmit={handleGetStarted}>
              {({ handleSubmit }) => (
                <form
                  onChange={handleChangeForm}
                  onSubmit={handleSubmit}
                  className={style.emailBox}
                >
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    style={{ maxWidth: '200px' }}
                  />
                  <button type="submit">Get Started</button>
                </form>
              )}
            </Formik>
            <div className={style.explanation}>
              Join the CareerTest.ai community and discover your best-fit career
              paths
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
