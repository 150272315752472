import { useEffect, useState } from 'react';
import './cookie-consent.css';

const COOKIE_CONSENT_KEY = 'cookie-consent-status';

export const useCookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consentStatus = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (!consentStatus) {
      setShowConsent(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, 'accepted');
    setShowConsent(false);
  };

  const denyCookies = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, 'denied');
    setShowConsent(false);
  };

  const CookieConsentBanner = () => {
    if (!showConsent) return null;

    return (
      <div
        className="cookie-consent-overlay"
        role="dialog"
        aria-labelledby="cookie-consent-title"
        aria-describedby="cookie-consent-description"
      >
        <div className="cookie-consent-popup">
          <div className="cookie-consent-content">
            <p id="cookie-consent-title" className="cookie-consent-text">
              Cookie Consent
            </p>
            <p id="cookie-consent-description" className="cookie-consent-text">
              We use cookies to enhance your browsing experience, serve
              personalized content, and analyze our traffic. By clicking "Accept
              All", you consent to our use of cookies. Read our{' '}
              <a href="/privacy-policy" className="cookie-consent-link">
                Privacy Policy
              </a>{' '}
              to learn more.
            </p>
          </div>
          <div className="cookie-consent-buttons">
            <button
              onClick={denyCookies}
              className="cookie-consent-button cookie-consent-button-deny"
            >
              Deny All
            </button>
            <button
              onClick={acceptCookies}
              className="cookie-consent-button cookie-consent-button-accept"
            >
              Accept All
            </button>
          </div>
          <button
            onClick={denyCookies}
            className="cookie-consent-close"
            aria-label="Close cookie consent popup"
          >
            ✕
          </button>
        </div>
      </div>
    );
  };

  return {
    showConsent,
    acceptCookies,
    denyCookies,
    CookieConsentBanner,
  };
};

export default useCookieConsent;
