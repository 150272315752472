import useCookieConsent from 'hooks/useCookieConsent';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import AppRouter from './AppRouter';
import ContextsWrapper from './ContextsWrapper';
import './OverWrite.scss';

async function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function App() {
  const { CookieConsentBanner } = useCookieConsent();
  async function loadRazorpay() {
    await loadScript('https://checkout.razorpay.com/v1/checkout.js');
  }

  useEffect(() => {
    window.addEventListener('online', () => {
      toast.success("Hurray, You're back online", { icon: false });
    });

    window.addEventListener('offline', () => {
      toast.error("Oops, You're offline", { icon: false });
    });
    loadRazorpay();
  }, []);

  return (
    <BrowserRouter>
      <ContextsWrapper>
        <ToastContainer
          position="bottom-right"
          autoClose={2037}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
        />
        <CookieConsentBanner />
        <AppRouter />
      </ContextsWrapper>
    </BrowserRouter>
  );
}

export default App;
