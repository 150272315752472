import ClipLoader from 'react-spinners/ClipLoader';

export default function InlineLoader({ size, ...props }) {
  return (
    <ClipLoader
      color={props.color ? props.color : '#3D92ED'}
      loading
      size={size}
    />
  );
}
