import { AppContextProvider } from 'contexts/AppContext';
import { SidebarContextProvider } from 'contexts/SidebarContext';
import { UserContextProvider } from 'contexts/UserContext';

export default function ContextsWrapper({ children }) {
  return (
    <AppContextProvider>
      <UserContextProvider>
        <SidebarContextProvider>{children}</SidebarContextProvider>
      </UserContextProvider>
    </AppContextProvider>
  );
}
