import clsx from 'clsx';
import { Link } from 'react-router-dom';

import LogoImg from 'assets/images/main/careertest_logo.png';
import WithPadding from 'components/shared/WithPadding';
import { BsLinkedin } from 'react-icons/bs';
import { FaFacebook, FaInstagramSquare, FaYoutube } from 'react-icons/fa';

import style from './Footer.module.scss';

const images = [
  {
    img: <FaFacebook size={30} />,
    link: 'https://www.facebook.com/careertestai',
  },
  {
    img: <FaInstagramSquare size={30} style={{ marginTop: '-2px' }} />,
    link: 'https://www.instagram.com/careertest.ai',
  },
  {
    img: <FaYoutube size={30} />,
    link: 'https://www.youtube.com/@careertestai',
  },
  {
    img: <BsLinkedin size={30} />,
    link: 'https://www.linkedin.com/company/careertestai',
  },
];

const Links1 = [
  { name: 'CareerPedia', link: '/careerpedia' },
  { name: 'Login', link: '/auth/login' },
  { name: 'Sign Up', link: '/auth/register' },
];

const Links2 = [
  { name: 'CareerTest.ai for Organizations', link: '/for-organization' },
];

const Links3 = [
  { name: 'Contact', link: '/contact-us' },
  { name: 'FAQs', link: '/faqs' },
  { name: 'Terms & Conditions', link: '/terms-and-conditions' },
  { name: 'Privacy Policy', link: '/privacy-policy' },
  { name: 'Refund Policy', link: '/refund-policy' },
];

export default function Footer() {
  return (
    <div className={style.wrapper}>
      <WithPadding className={style.main}>
        <div className={clsx(style.col)}>
          <div className={style.logo}>
            <img src={LogoImg} alt="" />
          </div>
          <div className={style.right}>
            <div className={clsx(style.text, style.textGray)}>
              <div>Follow us on</div>
            </div>
            <div className={style.socialHandles}>
              {images.map((image, index) => (
                <span
                  key={index}
                  onClick={() => {
                    window.open(image.link);
                  }}
                >
                  {image.img}
                </span>
              ))}
            </div>
            <div className={clsx(style.text, style.textGray)}>
              <div>© CareerTest.ai 2024-25</div>
            </div>
          </div>
        </div>

        <div className={style.linksWrapper}>
          <div className={clsx(style.linksOne, style.col, style.links)}>
            <div className={style.linkhead}>For Individuals</div>
            {Links1.map(({ name, link }, index) => (
              <Link key={index} className={style.link} to={link}>
                {name}
              </Link>
            ))}
          </div>
          <div className={clsx(style.linksTwo, style.col, style.links)}>
            <div className={style.linkhead}>For Organizations</div>
            {Links2.map(({ name, link }, index) => (
              <Link key={index} className={style.link} to={link}>
                {name}
              </Link>
            ))}
          </div>
          <div className={clsx(style.linksThree, style.col, style.links)}>
            <div className={style.linkhead}>Quick Links</div>
            {Links3.map(({ name, link }, index) => (
              <Link key={index} className={style.link} to={link}>
                {name}
              </Link>
            ))}
          </div>
        </div>
      </WithPadding>
      {/*
      <WithPadding className={style.header}>
        <div className={style.right}>
          <div className={clsx(style.text, style.textGray)}>
            <div>Follow us on</div>
          </div>
          <div className={style.socialHandles}>
            {images.map((image) => (
              <span
                onClick={() => {
                  window.open(image.link);
                }}
              >
                {image.img}
              </span>
            ))}
          </div>
        </div>
      </WithPadding> */}
    </div>
  );
}
